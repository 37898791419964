import { EditorReadyFn, FlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import focusTimeComp from './components/focusTime/editor.controller';
import { getFrameUrl } from './utils/editor';
import { AppApi } from './types';

const TOKEN = 'token';
let isClassicEditor: boolean = true;
export const editorReady: EditorReadyFn = async (
  editorSDK,
  token,
  { firstInstall, initialAppData },
  flowApi: FlowAPI,
) => {
  console.log(firstInstall ? 'FIRST RUN' : 'RUN');
  if (flowApi.environment.isEditorX) {
    isClassicEditor = false;
  }
  openSidePanel(editorSDK, flowApi);
};

const openSidePanel = async (editorSdk: FlowEditorSDK, flowAPI: FlowAPI) => {
  const toolsMenuItemOptions = {
    title: flowAPI.translations.t('app.title'),
  };
  const toolsPanelOptions = {
    title: flowAPI.translations.t('app.settings.title'),
    width: 288,
    height: 275,
    url: getFrameUrl(focusTimeComp.type, 'Settings'),
    initialPosition: { x: 100, y: 100 },
  };
  await editorSdk.editor.registerToolsPanel(
    '',
    toolsMenuItemOptions,
    toolsPanelOptions,
  );
};

const createPrivateAPI = (): AppApi => {
  return {
    getIsClassicEditor: (): boolean => isClassicEditor,
  };
};

export const exports = () => ({
  private: createPrivateAPI(),
});
